import React from "react"

export const productList = [
{PartNo : "3142631002",Name :"counter shaft",Wt:"4.700",Car : "Mercedes"},
{PartNo : "3142631202",Name :"counter shaft",Wt:"4.890",Car : "Mercedes"}, 
{PartNo : "3142631414",Name :"IVth Gear On Countershaft",Wt :"1.860", Car : "Mercedes"},
{PartNo : "3142603644",Name :"IIIrd Gear",Wt :"1.240", Car : "Mercedes"},
{PartNo : "3142630710",Name : "Vth Gear On Countershaft",Wt :"2.080",Car : "Mercedes"},
{PartNo : "3142630810",Name : "Vth Gear On Countershaft",Wt:"2.1",Car : "Mercedes"},
{PartNo : "3142630512",Name : "IInd Gear",Wt : "1.860", Car : "Mercedes"},
{PartNo : "3142600725",Name : "Reverse Gear",Wt : "1.240", Car : "Mercedes"},
{PartNo : "3142620902",Name : "Main Drive Gear",Wt :"1.9",Car :"Mercedes"},
{PartNo : "3142620802",Name : "Main Drive Gear",Wt :"1.9",Car :"Mercedes"},
{PartNo : "3142621002",Name : "Main Drive Gear",Wt :"1.9",Car :"Mercedes"},
{PartNo : "3142620702",Name : "Main Drive Gear",Wt :"1.9",Car :"Mercedes"},
{PartNo : "3142602444",Name : "IVth Gear on Main Shaft",Wt :"0.930",Car :"Mercedes"},
{PartNo : "3142620705",Name : "Main Shafts",Wt :"3.4",Car :"Mercedes"},
{PartNo : "3142620405",Name : "Main Shafts",Wt :"3.4",Car :"Mercedes"},
{PartNo : "1249304572",Name : "Main shaft with Collar", Wt :"4.3",Car :"Mercedes"},
{PartNo : "3093501439",Name : "Crown Wheel Pinion",Wt :"7",Car :"Mercedes"},
{PartNo : "3183501139",Name : "Crown Wheel Pinion",Wt :"13.6",Car :"Mercedes"},
{PartNo : "3183500239",Name : "Crown Wheel Pinion",Wt :"13.6",Car :"Mercedes"},
{PartNo : "3183500739",Name : "Crown Wheel Pinion",Wt :"13.6",Car :"Mercedes"}, 
{PartNo : "3143500026",Name : "BEVEL STAR KIT",Wt :"3.76(2x0.800,4x0.27,1x1.080",Car :"Mercedes"},
{PartNo : "3143530715",Name : "BEVEL STAR KIT",Wt :".800",Car :"Mercedes"},
{PartNo : "3163530014",Name : "BEVEL STAR KIT",Wt :"0.27",Car :"Mercedes"},
{PartNo : "3183530020",Name : "BEVEL STAR KIT",Wt :"1.080",Car :"Mercedes"},
{PartNo : "3103571101",Name : "AXLE SHAFT",Wt :"8.76",Car :"Mercedes"},
{PartNo : "3142621535",Name : "GEAR SYNCHRONISER HUB",Wt :"0.7",Car :"Mercedes"},
{PartNo : "3142621223",Name : "GEAR SLIDING SLEEVE",Wt :"0.55",Car :"Mercedes"},
{PartNo : "1268303056",Name : "CONSTANT GEAR",Wt :"6.430",Car :"ZF"},
{PartNo : "1268304005",Name : "GEAR 5TH SPEED",Wt :"5.820",Car :"ZF"},
{PartNo :"",Name : "GEAR",Wt :"6",Car :"ZF"},
{PartNo :"",Name : "GEAR",Wt :"5.550",Car :"ZF"},
{PartNo : "1268302015",Name : "INPUT SHAFT",Wt :"6.300",Car :"ZF"},
{PartNo : "1268304004",Name : "GEAR 4TH SPEED",Wt :"6.300",Car :"ZF"},
{PartNo : "1268303005",Name : "Double GEAR",Wt :"6.050",Car :"ZF"},
{PartNo : "1268303004",Name : "Gear 5th Speed",Wt :"5.550",Car :"ZF"},
{PartNo : "12503020196",Name : "INPUT SHAFT",Wt :"5.380",Car :"ZF"},
{PartNo : "1268302049",Name : "INPUT SHAFT",Wt :"5.270",Car :"ZF"},
{PartNo : "1268302001",Name : "INPUT SHAFT",Wt :"5.670",Car :"ZF"},
{PartNo : "1268302047",Name : "INPUT SHAFT",Wt :"5.490",Car :"ZF"},
{PartNo : "1249202538",Name : "INPUT SHAFT",Wt :"2.2",Car :"ZF"},
{PartNo : "1249202526",Name : "INPUT SHAFT",Wt :"2.2",Car :"ZF"},
{PartNo : "6330629",Name : "17T/36T/10S Top Gear",Wt :"2.2",Car :"Bedford"},
{PartNo : "6349692",Name : "18T/30T/10S Top Gear",Wt :"2.275",Car :"Bedford"},
{PartNo : "7083022",Name : "13 T Lay Shaft/Counter Shaft",Wt :"4.200",Car :"Bedford"},
{PartNo : "6367582",Name : "23T Main Shaft 12 Groove/12 Spline",Wt :"4.450",Car :"Bedford"},
{PartNo : "6306157",Name : "39T 1st Gear (On Main Shaft)",Wt :"3.350",Car :"Bedford"},
{PartNo : "7204904",Name : "34T/20T 2nd Double Gear (On Main Shaft)",Wt :"1.850",Car :"Bedford"},
{PartNo : "7204903",Name : "33T/20T 2nd Double Gear (on Main Shaft)",Wt :"1.750",Car :"Bedford"},
{PartNo : "6330717",Name : "24T 3rd Gear Double (on Main Shaft)",Wt :"1.300",Car :"Bedford"},
{PartNo : "7136411",Name : "24T 2nd Gear Single (On Counter Shaft)",Wt :"0.850",Car :"Bedford"},
{PartNo : "7144744",Name : "25T 2nd Gear Single (On Counter Shaft)",Wt :"",Car :"Bedford"},
{PartNo : "6330630",Name : "33T 3rd GEAR (On Counter Shaft)",Wt :"1.500",Car :"Bedford"},
{PartNo : "6330631",Name : "40T 4th (TOP) GEAR SINGLE(On Counter Shaft)",Wt :"2.150",Car :"Bedford"},
{PartNo : "6349694",Name : "39T (TOP) GEAR SINGLE (On Counter Shaft)",Wt :"1.950",Car :"Bedford"},
{PartNo : "7151189",Name : "REVERSE GEAR WITH BRUSH",Wt :"",Car :"Bedford"},
{PartNo : "6379009",Name : "12T/30 GROOVE INNER HUB",Wt :"0.550",Car :"Bedford"},
{PartNo : "6379009",Name : "30T CLUTCH HUB",Wt :"0.725",Car :"Bedford"},
{PartNo :"",Name : "DIFF STAR KIT COMPLETE",Wt :"5.100",Car :"Bedford"},
{PartNo :"",Name : "31T CRANK GEAR",Wt :"0.750",Car :"Bedford"},
{PartNo :"5109000",Name : "Main Shaft Gutka",Wt :"0.600",Car :"Fiat Tractor 480/640"},
{PartNo :"579295",Name : "Gear Small Carrier Unit",Wt :"0.140",Car :"Fiat Tractor 480/640"},
{PartNo :"579294",Name : "Ring Carrier Unit",Wt :"3.350",Car :"Fiat Tractor 480/640"},
{PartNo :"4980608",Name : "CW/P",Wt :"2.850+4.650",Car :"Fiat Tractor 480/640"},
{PartNo :"5103868",Name : "Star Big Handi (with Hole)",Wt :"1.100",Car :"Fiat Tractor 480/640"},
{PartNo :"5103869",Name : "Star B/G Handi (without hole)",Wt :"0.800",Car :"Fiat Tractor 480/640"},
{PartNo :"5103870",Name : "Star Small Handi",Wt :"0.250",Car :"Fiat Tractor 480/640"},
{PartNo :"5102794",Name : "FOURTH(4th) GEAR",Wt :"1.400",Car :"Fiat Tractor 480/640"},
{PartNo :"5102789",Name : "FIRST GEAR",Wt :"2.650",Car :"Fiat Tractor 480/640"},
{PartNo :"5102790",Name : "SECOND GEAR",Wt :"2.100",Car :"Fiat Tractor 480/640"},
{PartNo :"5102893",Name : "RING 1st-2nd GEAR",Wt :"1.550",Car :"Fiat Tractor 480/640"},
{PartNo :"5102792",Name : "THIRD(3rd) GEAR",Wt :"1.700",Car :"Fiat Tractor 480/640"},
{PartNo :"5102795",Name : "MAIN SHAFT",Wt :"2.200",Car :"Fiat Tractor 480/640"},
{PartNo :"5102147",Name : "RING 3rd-4th GEAR",Wt :"0.475",Car :"Fiat Tractor 480/640"},
{PartNo :"598185",Name : "REVERSE GEAR",Wt :"0.850",Car :"Fiat Tractor 480/640"},
{PartNo :"598176",Name : "GEAR POLY SHAFT",Wt :"1.300",Car :"Fiat Tractor 480/640"},
{PartNo :"4985299",Name : "POLY SHAFT",Wt :"2.875",Car :"Fiat Tractor 480/640"},
{PartNo :"4970715",Name : "GEAR PTO SHAFT",Wt :"3.600",Car :"Fiat Tractor 480/640"},
{PartNo :"4959916",Name : "ROTAVATOR SHAFT",Wt :"2.000",Car :"Fiat Tractor 480/640"},
{PartNo :"563511",Name : "PTO GEAR (NEW MODEL)",Wt :"0.400",Car :"Fiat Tractor 480/640"},
{PartNo :"4607772",Name : "BALANCER GEAR",Wt :"1.050",Car :"Fiat Tractor 480/640"},
{PartNo :"4607773",Name : "BALANCER GEAR",Wt :"0.950",Car :"Fiat Tractor 480/640"},
{PartNo :"5118153",Name : "PTO GEAR (OLD MODEL)",Wt :"0.300",Car :"Fiat Tractor 480/640"},
{PartNo :"0410132",Name : "CrankGear1",Wt :"0.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"125002",Name : "Gear",Wt :"1.950",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1671704M1",Name : "Pto Gear Shaft2",Wt :"1.200",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1671706M3",Name : "Pto Gear",Wt :"3.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1682688M1M",Name : "First Gear",Wt :"4.150",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1682690M1",Name : "Gear Pinion",Wt :"21.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1683757M91",Name : "Crown Wheel & Pinion",Wt :"12.3+2.950",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1687642M1",Name : "Main Shaft",Wt :"3.950",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1687643M1",Name : "Carrier Shaft/Main Shaft",Wt :"3.600",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3599630M1",Name : "Coupler Hyd. Pump Shaft(Thick with collar)",Wt :"1.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1688760M91",Name : "Crown Wheel & Pinion1",Wt :"13.550+3.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693733M1",Name : "Gear Big Rear Wheel Carrier (middle)",Wt :"1.700",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693734M1",Name : "Gear Small Rear wheel Carrier",Wt :"1.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693755M1",Name : "Gear-4",Wt :"2",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693760M1",Name : "Drive shaft Shaft Gear",Wt :"2",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693790M1",Name : "shaft Shaft Gear",Wt :"",Car :"Massey Ferguson (Perkins)"},
{PartNo :"180415M1",Name : "Gear Pinion1",Wt :"1.160",Car :"Massey Ferguson (Perkins)"},
{PartNo :"180419M1",Name : "Gear Pinion3",Wt :"1.750",Car :"Massey Ferguson (Perkins)"},
{PartNo :"183040M1",Name : "Reverse Gear",Wt : "0065",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1868535",Name : "Shaft1",Wt : "0065",Car :"Massey Ferguson (Perkins)"},
{PartNo :"184770M2",Name : "Coupler",Wt : "0.600+0.650",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1862422M1",Name : "Shaft1",Wt :"2.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1862423M3",Name : "Drive shaft Shaft1",Wt :"2.700",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1868530M91",Name : "Shaft Gear (Gulla)",Wt :"1.200",Car :"Massey Ferguson (Perkins)"},
{PartNo :"2746322M1",Name : "Star Gear1",Wt :"0.450",Car :"Massey Ferguson (Perkins)"},
{PartNo :"880955M1",Name : "Carrier shaft / Main Shaft Carrier Unit",Wt :"2.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"885518M1",Name : "All Models",Wt : "1.300",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1683781M1",Name : "Differential Cross Kit Complete (pcs set)",Wt : "",Car :"Massey Ferguson (Perkins)"},
{PartNo :"894769M1",Name : "Rear Wheel Gear1",Wt : "1.200",Car :"Massey Ferguson (Perkins)"},
{PartNo :"897005M1",Name : "Rear Wheel Gear2",Wt : "",Car :"Massey Ferguson (Perkins)"},
{PartNo :"906468M1",Name : "Gear Counter Mesh",Wt : "3.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"906469M1",Name : "Gear Mesh",Wt : "",Car :"Massey Ferguson (Perkins)"},
{PartNo :"906472M2",Name : "Counter shaft",Wt : "2.850",Car :"Massey Ferguson (Perkins)"},
{PartNo :"964916M1",Name : "Gear Gear 3rd Pinion",Wt : "1.050",Car :"Massey Ferguson (Perkins)"},
{PartNo :"964925M2",Name : "haft Drive shaft / Top Gear",Wt : "2.600",Car :"Massey Ferguson (Perkins)"},
{PartNo :"0410241",Name : "CAM GEAR",Wt : "0.950",Car :"Massey Ferguson (Perkins)"},
{PartNo :"125001",Name : "TOP SHAFT/Drive shaft SHAFT",Wt : "3.350",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1660288M94",Name : "PTO SHAFT",Wt : "4.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"964926M3",Name : "Pinion Main Shaft / Input Shaft",Wt : "2.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1671558M1",Name : "CARRIER UNIT RING",Wt : "5.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1680189",Name : "COUPLING CROWN WHEEL",Wt : "5.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1682691M1",Name : "DOUBLE GEAR",Wt : "3.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1693732M1",Name : "RING GEAR - REAR WHEEL CARRIER",Wt : "6.750",Car :"Massey Ferguson (Perkins)"},
{PartNo :"180420M1",Name : "Top gear",Wt : "2.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1866557M1",Name : "gear",Wt : "0.400",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1867424M2",Name : "RING GEAR CARRIER UNIT",Wt : "0.400",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1867820M1",Name : "GEAR ",Wt : "3.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1868533M1",Name : "shaft ",Wt : "2.650",Car :"Massey Ferguson (Perkins)"},
{PartNo :"183085",Name : "COUNTER SHAFT ",Wt : "5.000",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1868538",Name : "HYD. PUMP SHAFT COUPLER ",Wt : "",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31164362",Name : " TIMING GEAR",Wt : "2.550",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1870538M92",Name : "CARRIER UNIT",Wt : "4.900",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1871924M1",Name : "COUPLER REAR DRIVE",Wt : "0.550",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1889775M92",Name : "CROWN WHEEL PINION",Wt : "10.550+4.400",Car :"Massey Ferguson (Perkins)"},
{PartNo :"1660688M91",Name : "CROWN WHEEL PINION",Wt : "10.550+4.400",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31164582",Name : "GEAR ",Wt : "0.350",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31164631",Name : "FIP GEAR ",Wt : "1.100",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31165461",Name : "OIL PUMPGEAR ",Wt : "0.800",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31165531",Name : "OIL PUMP GEAR ",Wt : "0.800",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3116W001",Name : "GEAR ",Wt : "0.200",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31171276",Name : "SHAFT",Wt : "1.500",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31171461",Name : "GEAR",Wt : "0.200",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3117C071",Name : "GEAR",Wt : "0.750",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3117L021",Name :"CAM  GEAR",Wt : "1.600",Car :"Massey Ferguson (Perkins)"},
{PartNo :"31171618",Name : "CRANK GEAR",Wt : "0.700",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3117V003",Name : "BALANCER SHAFT",Wt : "0.750",Car :"Massey Ferguson (Perkins)"},
{PartNo :"32731127",Name : "BALANCER SHAFT",Wt : "",Car :"Massey Ferguson (Perkins)"},
{PartNo :"3599630M1",Name : "COUPLER HYD. PUMP  (Thick with Collar)",Wt : "1.000",Car :"Massey Ferguson (Perkins)"},
{PartNo : "41115025",Name : "OIL PUMP GEAR",Wt : "0.200",Car :"Massey Ferguson (Perkins)"},
{PartNo : "41115136",Name : "FIP GEAR",Wt : "1.500",Car :"Massey Ferguson (Perkins)"},
{PartNo : "4111A013",Name : "TIMING GEAR",Wt : "2.000",Car :"Massey Ferguson (Perkins)"},
{PartNo : "518861M91",Name : "PINION PLATE",Wt : "1.900",Car :"Massey Ferguson (Perkins)"},
{PartNo : "84447",Name : "BALANCER GEAR",Wt : "0.750",Car :"Massey Ferguson (Perkins)"},
{PartNo : "880069M2",Name : "COUPLER REAR DRIVE",Wt : "0.800",Car :"Massey Ferguson (Perkins)"},
{PartNo : "532906M4",Name : "GEAR",Wt : "1.800",Car :"Massey Ferguson (Perkins)"},
{PartNo : "886548M1",Name : "COUPLER SHAFT REAR DRIVE",Wt : "2.000",Car :"Massey Ferguson (Perkins)"},
{PartNo : "894302M2",Name : "STAR JOINT CROSS",Wt : "1.300",Car :"Massey Ferguson (Perkins)"},
{PartNo : "2746135M2",Name : "STAR JOINT CROSS",Wt : "1.300",Car :"Massey Ferguson (Perkins)"},
{PartNo : "894772M1",Name : "RING GEAR REAR WHEEL",Wt : "8.050",Car :"Massey Ferguson (Perkins)"},
{PartNo : "897711M92",Name : "CARRIER UNIT",Wt : "3.400",Car :"Massey Ferguson (Perkins)"},
{PartNo : "897039M1",Name : "AXLE",Wt : "15.500",Car :"Massey Ferguson (Perkins)"},
{PartNo : "884850M3",Name : "AXLE",Wt : "11.300",Car :"Massey Ferguson (Perkins)"},
{PartNo : "3117119",Name : "GEAR",Wt : "5.400",Car :"UTB Romania"},
{PartNo : "3117114",Name : "GEAR",Wt : "5.950",Car :"UTB Romania"},
{PartNo : "3117115",Name : "GEAR",Wt : "3.238",Car :"UTB Romania"},
{PartNo : "3117116",Name : "GEAR",Wt : "3.328",Car :"UTB Romania"},
{PartNo : "3117118",Name : "GEAR",Wt : "3.497",Car :"UTB Romania"},
{PartNo : "3224103",Name : "SHAFT GEARED",Wt : "8.512",Car :"UTB Romania"},
{PartNo : "",Name : "CLUTCH SHAFT",Wt : "",Car :"UTB Romania"},
{PartNo :"",Name : "MAIN SHAFT",Wt : "",Car :"UTB Romania"},
{PartNo : "3125014",Name : "GEAR 72 T LH",Wt : "32.200",Car :"UTB Romania"},
{PartNo : "3125013",Name : "GEAR 72 T RH",Wt : "32.000",Car :"UTB Romania"},
{PartNo :"",Name : "CROWN PINION FRONT",Wt : "13.5",Car :"UTB Romania"},
{PartNo :"",Name : "CROWN PINION REAR",Wt : "21.400",Car :"UTB Romania"},
{PartNo :"",Name : "PTO GEAR BIG AND SMALL",Wt : "",Car :"UTB Romania"},
{PartNo :"",Name : "SPIDER GEAR WITH NEEDLE PIN",Wt : "",Car :"UTB Romania"},
{PartNo : "333332510",Name :"3rd GEAR",Wt : "1.150",Car :"Hino TOYOTA"}, 
{PartNo : "333342000",Name :"O.D GEAR",Wt : "0.530",Car :"Hino TOYOTA"} ,
{PartNo :"333212020",Name :"MAIN SHAFT",Wt : "5.200",Car :"Hino TOYOTA"},
 {PartNo :"333113620",Name :"TOP GEAR",Wt : "1.600",Car :"Hino TOYOTA"} ,
{PartNo : "333113750",Name :"TOP GEAR 12 SPLINE ",Wt : "4.450",Car :"Hino TOYOTA"},
{PartNo : "333112181",Name :"TOP GEAR 10 SPLINE ",Wt : "2.350",Car :"Hino TOYOTA"},
{PartNo : "333311271",Name :"4TH GEAR ",Wt : "1.025",Car :"Hino TOYOTA"},
{PartNo :"334221211",Name :"4rh GEAR ",Wt : "2.240",Car :"Hino TOYOTA"},
{PartNo :"334112780",Name :"COUNTER SHAFT",Wt : "10.600",Car :"Hino TOYOTA"},
{PartNo :"3811090379",Name :"CW/P (7X39T)",Wt : "32.000",Car :"Hino TOYOTA"},
{PartNo :"333372540",Name :"6th GEAR UPPER",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"334292210",Name :"6th GEAR LOWER",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"334262140",Name :"GEAR L",Wt : "3.300",Car :"Hino TOYOTA"},
{PartNo :"334021061",Name :"COUNTER",Wt : "12.500",Car :"Hino TOYOTA"},
{PartNo :"334021281",Name :"COUNTER",Wt : "12.900",Car :"Hino TOYOTA"},	
{PartNo :"334112730",Name :"COUNTER",Wt : "12.900",Car :"Hino TOYOTA"},
{PartNo :"333113180",Name :"TOP",Wt : "2.800",Car :"Hino TOYOTA"},
{PartNo :"334113260",Name :"COUNTER",Wt : "14.500",Car :"Hino TOYOTA"},
{PartNo :"333114771",Name :"TOP",Wt : "3.100",Car :"Hino TOYOTA"},
{PartNo :"334113470",Name :"COUNTER",Wt : "14.400",Car :"Hino TOYOTA"},
{PartNo :"334262110",Name :"GEAR",Wt : "3.200",Car :"Hino TOYOTA"},
{PartNo :"334291930",Name :"GEAR",Wt : "2.800",Car :"Hino TOYOTA"},
{PartNo :"333372460",Name :"GEAR",Wt : "1.400",Car :"Hino TOYOTA"},
{PartNo :"334262641",Name :"GEAR",Wt : "3.400",Car :"Hino TOYOTA"},
{PartNo :"334231620",Name :"GEAR",Wt : "2.700",Car :"Hino TOYOTA"},
{PartNo :"333114240",Name :"TOP",Wt : "4.400",Car :"Hino TOYOTA"},
{PartNo :"334112671",Name :"COUNTER",Wt : "5.800",Car :"Hino TOYOTA"},
{PartNo :"333113830",Name :"TOP",Wt : "2.900",Car :"Hino TOYOTA"},
{PartNo :"412212020",Name :"CROWN",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"334292490",Name :"GEAR",Wt : "2.700",Car :"Hino TOYOTA"},
{PartNo :"412114160",Name :"PINION",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"412212080",Name :"CROWN",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"412112570",Name :"PINION",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"412213210",Name :"CROWN PINION",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"412214000",Name :"CROWN PINION",Wt : "",Car :"Hino TOYOTA"},
{PartNo :"3270415",Name :"Top Gear",Wt : "4.500",Car :"Mazda"},
{PartNo :"3270415",Name :"Top Gear small",Wt : "",Car :"Mazda"},
{PartNo :"3270472",Name :"Counter Gear",Wt : "14.200",Car :"Mazda"},
{PartNo :"3270916",Name :"O.D Gear Big",Wt : "2.900",Car :"Mazda"},
{PartNo :"3270419",Name :"O.D Gear small",Wt : "1.200",Car :"Mazda"},
{PartNo :"3270960",Name :"Main Shaft",Wt : "7.900",Car :"Mazda"},
{PartNo :"3270132",Name :"Clutch Inner Hub",Wt : "1.000",Car :"Mazda"},
{PartNo :"3270769",Name :"Clutch Sleeve",Wt : "0.800",Car :"Mazda"},
{PartNo :"17230",Name :"3rd Gea",Wt : "0.900",Car :"Mazda"},
{PartNo :"27110",Name : "CW/P",Wt : "9.400",Car :"Mazda"},
{PartNo :"1D201171",Name :"COUNTER SHAFT",Wt : "9.600",Car :"Canter"},
{PartNo :"1D202018",Name :"COUNTER SHAFT",Wt : "9.500",Car :"Canter"},
{PartNo :"1D201204",Name :"COUNTER SHAFT",Wt : "8.600",Car :"Canter"},
{PartNo :"730762",Name :"COUNTER SHAFT",Wt : "9.700",Car :"Canter"},
{PartNo :"ME603214",Name :"COUNTER SHAFT",Wt : "9.700",Car :"Canter"},
{PartNo :"ME603214",Name :"COUNTER SHAFT",Wt : "9.300",Car :"Canter"},
{PartNo :"ME603215",Name :"COUNTER SHAFT",Wt : "9.200",Car :"Canter"},
{PartNo :"",Name :"COUNTER SHAFT",Wt : "7.900",Car :"Canter"},
{PartNo :"43410",Name :"COUNTER SHAFT",Wt : "8.000",Car :"Canter"},
{PartNo :"ME602416",Name :"COUNTER SHAFT",Wt : "5.600",Car :"Canter"},
{PartNo :"",Name :"COUNTER SHAFT",Wt : "8.200",Car :"Canter"},
{PartNo :"",Name :"M.D SHFT",Wt : "2.200",Car :"Canter"},
{PartNo :"",Name :"M.D SHFT",Wt : "2.200",Car :"Canter"},
{PartNo :"ME603224",Name :"M.D SHFT",Wt : "1.700",Car :"Canter"},
{PartNo :"1D200177",Name :"M.D SHFT",Wt : "1.700",Car :"Canter"},
{PartNo :"1D000314",Name :"M.D SHFT",Wt : "1.700",Car :"Canter"},
{PartNo :"1D000193",Name :"M.D SHFT",Wt : "1.700",Car :"Canter"},
{PartNo :"1D000101",Name :"M.D SHFT",Wt : "1.600",Car :"Canter"},
{PartNo :"",Name :"M.D SHFT",Wt : "2.000",Car :"Canter"},
{PartNo :"ME606343",Name :"M.D SHFT",Wt : "2.000",Car :"Canter"},
{PartNo :"",Name :"M.D SHFT",Wt : "1.800",Car :"Canter"},
{PartNo :"ME601727",Name :"M.D SHFT",Wt : "1.700",Car :"Canter"},
{PartNo :"ME602417",Name :"IInd GEAR",Wt : "1.700",Car :"Canter"},
{PartNo :"1D000204",Name :"IInd GEAR",Wt : "1.900",Car :"Canter"},
{PartNo :"",Name :"IInd GEAR",Wt : "1.300",Car :"Canter"},
{PartNo :"",Name :"IInd GEAR",Wt : "1.300",Car :"Canter"},
{PartNo :"ME603229",Name :"IIIrd GEAR",Wt : "1.100",Car :"Canter"},
{PartNo :"",Name :"IIIrd GEAR",Wt : "0.900",Car :"Canter"},
{PartNo :"1D000102",Name :"IIIrd GEAR",Wt : "1.400",Car :"Canter"},
{PartNo :"",Name :"IIIrd GEAR",Wt : "1.000",Car :"Canter"},
{PartNo :"ME602798",Name :"O. D. GEAR",Wt : "0.700",Car :"Canter"},
{PartNo :"1D200173",Name :"O. D. GEAR",Wt : "0.600",Car :"Canter"},
{PartNo :"",Name :"O. D. GEAR",Wt : "0.500",Car :"Canter"},
{PartNo :"1D000102",Name :"O. D. GEAR",Wt : "1.100",Car :"Canter"},
{PartNo :"",Name :"SHIFTER SLEEVE",Wt : "0.300",Car :"Canter"},
{PartNo :"",Name :"SHIFTER SLEEVE",Wt : "0.400",Car :"Canter"},
{PartNo :"ME610827",Name :"INNER HUB",Wt : "0.400",Car :"Canter"},
{PartNo :"",Name :"INNER HUB",Wt : "0.500",Car :"Canter"},
{PartNo :"ME601153",Name :"Ist GEAR",Wt : "1.600",Car :"Canter"},
{PartNo :"ME601156",Name :"Rev GEAR",Wt : "",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "13.700",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "14.200",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "",Car :"Canter"},
{PartNo :"",Name :"CROWN PINION",Wt : "",Car :"Canter"},
{PartNo :"",Name :"BEVEL KIT",Wt : "",Car :"Canter"},
{PartNo :"3222620511",Name :"Ist SPEED GEAR",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620412",Name :"2nD SPEED GEAR",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222630513",Name :"2ND-3RD  SPEED GEAR COUNTER SHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222602444",Name :"3nD SPEED GEAR",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222602044",Name :"4TH SPEED GEAR",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222630614",Name :"4TH SPEED GEAR COUNTERSHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222630610",Name :"CONSTANT GEAR COUNTERSHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222630302",Name :"COUNTERSHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620045",Name :"FLANGE OUTPUT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620802",Name :"INPUT SHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"",Name :" INPUT SHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620705",Name :"MAIN SHAFT",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222600825",Name :"REVERSE GEAR",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620434",Name :"SYNCHRO CONE",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620735",Name :"SYNCHRO HUB",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620635",Name :"SYNCHRO HUB",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620137",Name :"SYNCHRO RING",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222620237",Name :"SYNCHRO RING",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222625650",Name :"BUSH",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3222626550",Name :"BUSH",Wt : "",Car :"Mercedes 911B"},
{PartNo :"",Name :"CROWN WHEEL & PINION 7X48",Wt : "",Car :"Mercedes 911B"},
{PartNo :"",Name :"CROWN WHEEL & PINION 7X48",Wt : "",Car :"Mercedes 911B"},
{PartNo :"3812620005",Name :"MAIN SHAFT",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3812620102",Name :"MAIN DRIVE GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3832630203",Name :"CONTER SHAFT",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3832600142",Name :"1st GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3022620312",Name :"2nd SPEED GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3022601544",Name :"3rd SPEED GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3022630410",Name :"CONSTANT MESH GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3022630614",Name :"4th SPEED GEAR ON COUNTERSHAFT",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3812601744",Name :"4th SPEED GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3832620033",Name :"REVERSE SPEED GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3022630313",Name :"TWIN GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3832630031",Name :"REVERSE IDLER GEAR",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3812620037",Name :"SYN. CONE",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3832620134",Name :"SYN. RING",Wt : "",Car :"Mercedes 911C"},
{PartNo :"3812620035",Name :"SYN. HUB 4th & 5th",Wt : "",Car :"Mercedes 911C"},
{PartNo :"ME636002",Name :"TOP",Wt :"3.100",Car :"FUSO "},
{PartNo :"ME630161",Name :"TOP",Wt :"3.600",Car :"FUSO "},
{PartNo :"ME631759",Name :"TOP",Wt :"3.500",Car :"FUSO "},
{PartNo :"ME636001",Name :"TOP",Wt :"3.100",Car :"FUSO "},
{PartNo :"ME636658",Name :"TOP",Wt :"2.900",Car :"FUSO "},
{PartNo :"ME636972",Name :"GEAR",Wt :"3.000",Car :"FUSO "},
{PartNo :"",Name :"GEAR IInd",Wt : "",Car :"FUSO "},
{PartNo :"",Name :"GEAR IInd",Wt : "",Car :"FUSO "},
{PartNo :"",Name :"COUNTER SHAFT",Wt : "9.000",Car :"FUSO "},
{PartNo :"",Name :"COUNTER SHAFT",Wt : "8.900",Car :"FUSO "},
{PartNo :"",Name :"MAIN SHAFT",Wt : "5.800",Car :"FUSO "},
{PartNo :"",Name :"Bevel Gear 4pc. Axel Gear 2pc",Wt : "1.550/1.7",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"l Gear ",Wt : "1.950",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear1 ",Wt : "0.850",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear2 ",Wt : "1.440",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear3 ",Wt : "3.850",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear4 ",Wt : "3.700",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear5 ",Wt : "3.450",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear6 ",Wt : "3.000",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear7 ",Wt : "2.750",Car :"Belarus TRACTOR "},
{PartNo :"",Name :"Gear8 ",Wt : "1.750",Car :"Belarus TRACTOR"},
{PartNo :"",Name :"Axle Shaft",Wt : "7.050",Car :"Belarus TRACTOR"},
{PartNo :"",Name :"Shaft",Wt : "3.500",Car :"Belarus TRACTOR"},
{PartNo :"15045ENW",Name :"GEAR",Wt : "1.310",Car :"BMC AUSTIN TRUCK"},
{PartNo :"KK821",Name :"GEAR",Wt : "2.040",Car :"BMC AUSTIN TRUCK"},
{PartNo :"57H8979",Name :"GEAR",Wt : "3.480",Car :"BMC AUSTIN TRUCK"},
{PartNo :"17H2106",Name :"GEAR",Wt : "3.230",Car :"BMC AUSTIN TRUCK"},
{PartNo :"G15203",Name :"M.D GEAR",Wt : "2.580",Car :"BMC AUSTIN TRUCK"},
{PartNo :"15115ENW",Name :"COUNTER SHAFT",Wt : "6.080",Car :"BMC AUSTIN TRUCK"},
{PartNo :"15116ENW",Name :"GEAR",Wt : "1.460",Car :"BMC AUSTIN TRUCK"},
{PartNo :"LA854",Name :"MAIN SHAFT",Wt : "5.210",Car :"BMC AUSTIN TRUCK"},
{PartNo :"17H2111",Name :"GEAR",Wt : "3.320",Car :"BMC AUSTIN TRUCK"},
{PartNo :"COUPLER",Name :"GEAR",Wt : "0.330",Car :"BMC AUSTIN TRUCK"},
]