import React,{useState} from 'react';


export default function ProductItem({Items,setToken,setVal,val}){



	const [divStyle,setDivStyle] = useState([{}]);

	React.useEffect(()=>{
		var temp = [{}];

		Items.map((t,i)=>{
			if(i!==0)
			temp = [...temp,{}]
		})

		setDivStyle(temp);
	},[])

	

	const changeColorFocus=(color,indx)=>{
		//console.log("triggered by"+indx+color);
		var temp = [];
		
		divStyle.map((t,i)=>{
			if(i===indx)
			{
				temp.push({backgroundImage : `linear-gradient(${color},white)`,boxShadow : 'grey 1vw 1vh'});
			}
			else
			{
				temp.push({});
			}

		})
		
		
		setDivStyle(temp);
		
	}

	const changeColorNoFocus=(indx)=>{
		var temp = [];
		divStyle.map(()=>{temp.push({})});
		setDivStyle(temp);
	}

	const contentPage=(Name,indx)=>{
		setVal(Name);
		setToken(5);
	}

	return(
			<>
			<div className = "BrandContainer">
				{Items.map((Value,indx)=>{

					return(
							<div  key = {indx} className = "Brand" style = {divStyle[indx]} onMouseEnter={e=>changeColorFocus(Value.color,indx)}  onMouseLeave={e=>changeColorNoFocus(indx)} onClick = {e=>{contentPage(Value.Name,indx)}}>
								<div className = "ImgContainer">
								<img src = {Value.img}/>
								</div>
								<p>{Value.Name}</p>
							</div>

				);})}
			</div>
			</>

);
}