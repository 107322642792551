import React from "react";

export default function Facilities({img1,presses}){


	return(

			<>
				<div className="item-wrapper">
                        <div className="text">
                            <h3>
                                Forging unit
                            </h3>
                            <ul>
                                <li>Horizontal forging press (upsetter machine ) - 600tonnes</li>
                                <li>ENOMOTO make friction screw-press(bevel teeth forging capacity) - 800tonnes </li>
                                <li> Vertical forging press - 200tonnes</li> 
                                <li>Inductotherm Electric Heating coils and end bar heater- 6 Nos </li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = {presses}/>
                        </div>
                    </div>
                    <div className="item-wrapper1">
                    <div className="text">
                            <h3>
                                Raw Machining unit
                            </h3>
                            <ul>
                                <li>CNC Turning Lathe machines Dia 400x 600mm: - 15 Nos</li>
                                <li>VMC 500 x 500 x 500:- 04 Nos</li>
                                <li>Centering facing (wmw) </li>
                                <li>Gun drill</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../V22.jpeg" />
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="text">
                            <h3>
                                Transmission Section 
                            </h3>
                            <ul>
                                <li>Gear Hobbing Machine up to 400 Dia capacities: - 22 Nos</li>
                                <li>Gear Shaving Machine up to 300 Dia capacity: - 09 Nos</li>
                                <li>Gear Rounding Machine: - 04 Nos</li>
                                <li>Gear Chamfering Machine: - 03 Nos</li>
                                <li>Gear Broaching Machine: - 06 Nos</li>
                                <li>Gear Shaper Machine Up to 200 Dia capacity: -09 Nos including 02 m/c with taper attachment.</li>
                                <li>Drilling Machine - 10 Nos</li>
                                <li>Milling Machine - 05 Nos </li>
                                
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../LC152.jpeg"/>
                        </div>
                    </div>
                    <div className="item-wrapper1">
                        <div className="text">
                            <h3>
                                Bevel Gear Generation Section
                            </h3>
                            <ul>
                                <li>For straight bevel production machines: 07 Nos</li>
                                <li>For production of spiral bevel Crown production machines GLEASON: - 06 Nos OERLIKON- 04 Nos</li>
                                <li>Pinion production machines: - 06 Nos</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../oerlikon.jpeg"/>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="text">
                            <h3>
                                Grinders& Finishing Machine
                            </h3>
                            <ul>
                                <li>Cylindrical Grinders: - 03 Nos</li>
                                <li>Internal Grinders: - 04 Nos</li>
                                <li>Thread Grinder: - 02Nos</li>
                                <li>GLeason Lapper: - 04 Nos</li>
                                <li>Spline grinder:- 01 Nos</li>
                                <li>Gear grinder NZA :- 02 Nos</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../grinding.png"/>
                        </div>
                    </div>
                    <div className="item-wrapper1">
                        <div className="text">
                            <h3>
                                Heat Treatment Facility
                            </h3>
                            <ul>
                                <li>Gas Carburizing Furnace: - 03 Nos</li>
                                <li>Sealed Quench furnace :- 01 Nos</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../HT.png"/>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="text">
                            <h3>
                                Surface Treatment Facility
                            </h3>
                            <ul>
                                <li>Shot Blasting Machine Table Type: - 01Nos</li>
                                <li>Blacodizing (Auto Blackening) Plant: - 01 Nos</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = "../../ShotBlasting.jpg"/>
                        </div>
                    </div>
                    <div className="item-wrapper1">
                        <div className="text">
                            <h3>
                                Testing facility 
                            </h3>
                            <ul>
                                <li>Lead and Profile Tester: - 01 Nos</li>
                                <li>Micro Hardness Tester: - 01 Nos</li>
                                <li>Hardness Tester: - 02 Nos</li>
                                <li>Electronic Microscope: - 01 Nos</li>
                                <li>Roll tester: - 01 Nos</li>
                                <li>Roll tester for Crown Wheel & pinion: - 4 Nos</li>
                            </ul>
                        </div>
                        <div className="FacilitiesImg">
                            <img src = {img1}/>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="text">
                            <h3>
                                Current Production Capacity
                            </h3>
                            <ul>
                                <li>Gears:-20,000 Gears/Month</li>
                                <li>Bevel Gears: - 10,000/Month</li>
                                <li>Spiral Bevel Gears: - 8,000set/Month</li>
                            </ul>
                        </div>
                        </div>
			</>

	);
}
