import React from "react"
import ProductItem from './ProductItem.js';


export default function ProductListing({img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,setVal,val,setToken}){

const list = [ {img : img1 , color : 'RoyalBlue',Name : 'TATA'},
			   {img : img2 , color : 'Red' ,Name : "Fiat"},
			   {img : img3 , color : 'MAroon' ,Name :"Bedford" },
			   {img : img4 , color : 'Red' ,Name : "Canter"},
			   {img : img5 , color : 'DarkRed' ,Name :"Belarus"},
			   {img : img6 , color : 'Silver' ,Name :"Hino"},
			   {img : img7 , color : 'Silver', Name : 'Mazda'},
			   {img : img8 , color : 'Silver', Name : 'Mercedes' },
			   {img : img9 , color : 'Red' , Name : "Massey Ferguson" },
			   {img : img10, color : "lightBlue" , Name : "ZF"},
			   {img : "../../UTB.png", color : "lightBlue" , Name : "UTB Romania"},
				]

				


	return(
		<>
		<div>
		
		<ProductItem Items = {list} setToken = {setToken} setVal = {setVal} val = {val}/>

		</div>
		</>


	);
}