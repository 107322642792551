import React from 'react';
import './SearchBar.css'
import { CiSearch } from "react-icons/ci";

export default function SearchBar({ClassName,setToken,val,setVal}){
	
	var valueKeeper="";

	const checkEnter=(e)=>{
	
		if(e.target.value.length === 0)
		{}

		else if(e.keyCode === 13)
		{
			setVal(e.target.value);
			//console.log(val);
			setToken(5);
		}
	}

	const searchTrig=(e)=>{
			

			{
			setVal(valueKeeper);
			setToken(5);
		}
	}

	return(
		<div className = "searchBar-container">
			

			 <input className={ClassName} placeholder = "Search.." onKeyDown = {e=>{checkEnter(e)}} onChange = {e=>{valueKeeper=e.target.value;}}/> 
			 <button className = "IconButton" onClick={e=>{searchTrig(e)}}><CiSearch /> </button>
		</div> 

	);
}