import React, {useState} from 'react';
import SearchBar from './Searchbar/SearchBar.js';
import HeaderList from './headerMenu/menubar.js';
import HeaderDropdown from './headerMenu/HeaderDropdown.js';
import { CiLogin } from "react-icons/ci";


const menu = [
				{name:"Home",link:""},
				{name:"About Us",link:""},
				{name:"Facilities",link:""},
				{name:"Product Listing",link:""},

];



function ContionalTest({width})
{
	



	return (width > 500) ? <HeaderList Menu = {menu}/> : <HeaderDropdown Menu = {menu}/>;

	/*<div className ="Header_Text"> 
			
		
			 <Contional_test width = {width}/>
			
		 	
			
			</div>
			<div className ="Header_Text"> 
			<p> Facilities </p>
			</div>
			<div className ="Header_Text"> 
			<p> Product Listing </p>
			</div>
		*/
}

export default function Header({serv,token,setToken,val,setVal}){

	const [width,setWidth] = useState(window.innerWidth);

	

	React.useEffect (()=>{
		const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	},[])

	//setVal("a");
	//console.log(val);

	return (
		<>
		<div className = "Header">

			<div className = "Logo">
				<img src = {serv} alt = "not found" />
			</div>

			<div className = "Header_Right">
			

			<div className ="Header_Text">
			
			<HeaderList Menu = {menu} ClassName = {"DesktopDisplay"} token = {token} setToken = {setToken}/>
			
			
		 	
			
			</div>


			<SearchBar ClassName = {"Header_Search"} val = {val} setVal = {setVal} token = {token} setToken = {setToken} />

			<div className = "Header_Text" ><button className = "IconButton" onClick={e=>{setToken(4)}}> <CiLogin className = "Icon" /> </button> </div>

			</div>

		</div>
		
		
			<HeaderList Menu = {menu} ClassName = {"mobileMenu"} token = {token} setToken = {setToken}/>
			

		</>
	);
} 