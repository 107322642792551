import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'




export default function AboutUs({img1 , img2}){

const spanStyle = {
  paddingTop: '20px',
   
  color: '#111111',
  
}

const divStyle = {
  display: 'flex',
  flexDirection : 'column',
  alignItems: 'left',
  justifyContent: 'left',
  backgroundSize: 'cover',
  backgroundRepeat : 'no-repeat',
  height: '60vw'
}

const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }
];

const timeline = [
			{year : "1994", text : "VV Enterprises is Established with 1st factory in Mayapuri,Delhi"},
			{year : "1998", text : "Crown Wheel pinion set manufacturing abilities acquired to supplement gear production"},
			{year : "2004", text : "For more space VV Enterprises moves operations to Bhiwadi, Rajasthan"},
			{year : "2005", text : "In house heat treatment capabilities are aquired to maintain quality"},
			{year : "2009", text : "VV Enterprises starts exporting gears"},
			{year : "2015", text : "Upsetter forging is started to reduce costs for shafts and pinions"},
 			{year : "2017", text : "Second plant is set up in Bhiwadi,Rajasthan"},
			{year : "2024", text : "Precision bevel forging capabilities acquired"},
];

	return(
		<>
		<div>

			<div style = {{'backgroundImage':`url(${img2.url})`}} className = "AboutUsObjective"> 
			<h4  className = "AboutUsText"> We offer quality gears for replacement market and OEM gauranteed to fit and work according to customer needs and requirements  </h4>
			</div>
			<div className = "AboutUsIntro">
		  		<p className = "AboutUsText"> Established in 1994 by brothers Vinay Chopra and Vikas Chopra,with a focus and quality product and timely delivery. V.V. Enterprises has grown from a small manufacturing unit in delhi to now a manufacturing house covering over 90000 square feet area between 2 units </p>
		  		<div className = "AboutUsImg">
		  			<img src = {img1}/>
		  		</div>

		  	</div>
		  	<div className = "AboutUsInHouse">
		  		<div className = "AboutUsImg">
		  			<img src = {img1}/>
		  		</div>

		  		<p className = "AboutUsText">In order to ensure quality of goods, we have complete inhouse setup for gears, bevel sets, crown wheel pinion sets, shafts, axels</p>
		  		
		  	</div>

		  	<div className = "AboutUsTimeline">
		  		<h2 className = "AboutUsText"> Timeline </h2>
		  		<Slide slidesToScroll={1} slidesToShow={2} indicators={false} autoplay = {false} responsive={responsiveSettings}> 
		  			{timeline.map((Data,indx)=>(
		  				<div  key = {indx}>
		  				 	<h4 style={spanStyle}>  {Data.year}  </h4>
		  				 	<hr/>
		  				 	<p> {Data.text} </p>
		  				</div>
		  			))}
		  		</Slide>
		  	</div>
		</div>
		</>
	);
}