import React from 'react';
import Item from './Item.js';
import {productList} from "./productList.js";



export default function ItemDisplayer({value}){

const List = productList;

const retList = [];
console.log(value);
List.map((Val,indx)=>{

	if(Val.PartNo === value || Val.Name === value || (Val.Car.search(value)!==-1) )
	{
		console.log(Val);
		retList.push(Val);
	} 

})

if(retList.length === 0)
{	
	return(<div className = "Item-not-found"><img src = "../../placeholder.png"/> <h1> sorry item {value} not found </h1></div>);
}
else{
return(
		<>
		{retList.map((Val,indx)=>{
			return(
				<Item val = {Val}/>

			)

		})}

		</>

);
}

}