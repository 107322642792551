import React from 'react';

export default function Item({val})
{ console.log(val);
	return(

				
					<div className = "Item">
						<h2> {val.Name} </h2>
						<div className = "Item-Details">
						<img src = {`../../ItemImages/${val.PartNo}.jpg`} alt = {`../../ItemImages/${val.PartNo}.png`}/>
						<div className = "Item-Data">
							<div className = "Item-list">
							<p> OEM code	: 	</p>
							<p> Our code	: 	</p>
							<p> weight		: 	</p>
							<p> suitable to : 	</p> 
							</div>
							<div className = "Item-list">
							<p> {val.PartNo} </p>
							<p></p>
							<p> {val.Wt} KG</p>
							<p> {val.Car}</p>
							</div>						
						</div>
						</div>
					</div>

				

	);
}