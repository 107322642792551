
import './App.css';
import React,{useState} from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import ShowRunner from './ShowRunner.js'
//import Body from './components/body.js';
//import AboutUs from './components/AboutUS/AboutUs.js'
//import Facilities from './components/Facilities/Facilities.js'
//import ProductListing from './components/ProductListing/ProductListing.js'
//import Item from './components/ProductListing/Item.js';

/*<Body img1 = {"./finishedlasermarkedlogo.JPG"} img2 = {"./gs.gif"} img3 = {"./contact-bg.jpg"}/>
 <AboutUs img1 = {"./placeholder.png"} img2 = {"./img2.jpg"}/>
  <Facilities img1 = {"./placeholder.png"}/>
*/

function App() {
  const [token,setToken] = useState(0);
  const [val,setVal] = useState();
  
  return (
    <div className="App">
   
     <Header serv = {"./logo2.png"} token = {token} setToken = {setToken} val = {val} setVal = {setVal} />
     <ShowRunner setToken = {setToken} token = {token} val = {val} setVal = {setVal}/>

     <Footer serv = {"./logo2.png"} serv2 = {"./logo.png"}/>
     
    </div>
  );
}

export default App;
