

import React,{useState} from "react"


export default function Login(props){
	const [usrId,setUsrId] = useState('')
	const [psswrd,setPsswrd] = useState('')
	//const [real,setReal] = useState('')
	const [res,setRes] = useState(props.token);
	const [disabler,setDisabler] = useState(true);

	const [captcha,setCaptcha] = useState({question:'',answer:''})
	const [answer,setAnswer] = useState("");
	
	const [viewPsswrd,setViewPsswrd] = useState(true);
	
	const l1 = props.serv;
	const l2 = '192.168.194.253:4000'
	const l3 = 'localhost:4000'

	const UsrIdOnChange=(e)=>{
		setUsrId(e.target.value);
		if(psswrd.length>0 && usrId.length>0)
			setDisabler(false)
		else
			setDisabler(true)

	}

	const PsswrdOnChange=(e)=>{
		
		setPsswrd(e.target.value);
		if(psswrd.length>0 && usrId.length>0)
			setDisabler(false)
		else
			setDisabler(true)


		
	}

	

	

	const sendLoginData=()=>{
		
		/*fetch(l1+'/LoginData', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers:{
            	'Content-type' : 'application/json'
            },
            body:JSON.stringify({usrId,psswrd})
          	//console.log(JSON.stringify({dt,PrtyNmVl,PrtyGstVl,PrtyAddVl,ItmNmDt,ItmOeCdDt,ItmCdDt,ItmQty,ItmPrc}))
        }).then(response=>response.json()).then(setRes)
	*/
	}

	React.useEffect(()=>{
		//console.log(res);
		setUsrId('');
		setPsswrd('');
		//setReal('');
		setDisabler(true);
		//props.setToken(res);
		

	},[res])

	const LoginOnClick=(e)=>{
		//if(captcha.answer === answer)
		//{


		if(usrId ==='' || psswrd ==='')
			{//e.preventDefault;
			 	alert("Please fill User Id and/or Password")
			}
		else
			{
				sendLoginData();
			}
		//}
		/*
		else{
			setAnswer('');
			alert('captcha incorrect');
			GetCaptcha();
		}
		*/
	}

	return(
		<>
		<div className = "login-container">
		<div className ="title"> LOGIN</div>
		<hr/>
		<label className ="login-label">User Name: </label>
		<input className = "Input-box" value = {usrId} onChange = {e=>UsrIdOnChange(e)}/>
		<hr/>
		<label className ="login-label">Password: </label>
		<input className ="Input-box" type = {(viewPsswrd)?"password":"text"} value = {psswrd} onChange = {e=>PsswrdOnChange(e)}/>
		<label className ="login-label">View Password?<input type = "checkbox" onClick = {e=>{setViewPsswrd(!viewPsswrd)}}/></label>
		<hr/>
		<label className = "login-label"> Solve: </label>
		<p className = "Data-Element"> {captcha.question} </p>
		<input className ="Input-box" value = {answer} onChange = {e=>setAnswer(e.target.value)}/>
		<button disabled={disabler} className = "login-button" type="button" onClick = {e=>LoginOnClick(e)}> Login </button>
		</div>
		</>
	)

}
