import React from 'react';


/*{Menu.map((Opt,indx)=>{
			return(<ls> <a href={Opt.link}> {Opt.Name} </a> </ls>);

		})}*/

export default function HeaderList({Menu , ClassName,Token,setToken}){
	return (
		<div className = {ClassName}>
	
		{Menu.map((Val,indx)=>{
			return( <button key = {indx} className = "MenuButton" onClick={e=>{setToken(indx)}}> {Val.name} </button> 

			);
		})
		}
		</div>
	);
}